const CryptoJS = require('crypto-js')

class TokenGenerator {
  generateToken(sequence) {
    const requestId = Math.floor(Date.now() / 1000)
    const temp_token =
      'SOCOM' +
      '%|%' +
      requestId +
      '%|%' +
      'SOCOM-KEY-SW7b6KbWU3' +
      '%|%' +
      'SOCOM-SEC-q15fx7rEeX' +
      '%|%' +
      '67721160-d317-4ff3-82be-194626be0844' +
      '%|%' +
      sequence
    const token = CryptoJS.SHA256(temp_token).toString()

    const env = 'prod' //'dev'
    let baseURL = ''
    let saviId = ''

    const devUrl = 'http://socom-api.blipcom.id/'
    const prodUrl = 'https://rentetan.nextdigital.co.id/'
    const saviProdId =
      '80e9ac98660a7d19906477d9df6b0def7cd86911b7188672a06f5b1c49006082'
    const saviDevId =
      '006e6c1db61b2077d2bc1c01eaf4ebf8c3137724c874b39e54678bf666c7c8ce'
    // '0c552698ff42f1175329eb5d9f0db34e2ed62f4ee1d68b2dd44af149f1f16143'

    if (env === 'dev') {
      baseURL = devUrl
      saviId = saviDevId
    } else {
      baseURL = prodUrl
      saviId = saviProdId
    }
    // const baseURL = 'https://rentetan.nextdigital.co.id/'
    // console.log(temp_token)
    // console.log(requestId)
    return { requestId, token, baseURL, saviId }
  }

  encrypt(data) {
    const key = 'SOCOM-KEY-SW7b6KbWU3%|%SOCOM-SEC-q15fx7rEeX'
    return CryptoJS.AES.encrypt(data, key).toString()
  }

  // decrypt(encryptedData) {
  //   const key = CryptoJS.SHA256('SOCOM-KEY-SW7b6KbWU3%|%SOCOM-SEC-q15fx7rEeX')

  //   // Assuming the data is in OpenSSL's legacy format
  //   const decryptedData = CryptoJS.AES.decrypt({
  //     ciphertext: CryptoJS.enc.Base64.parse(encryptedData),
  //   }, key, {
  //     format: CryptoJS.format.OpenSSL,
  //   })

  //   return JSON.parse(decryptedData.toString(CryptoJS.enc.Utf8))
  // }

  decrypt(encryptedData) {
    const key = 'SOCOM-KEY-SW7b6KbWU3%|%SOCOM-SEC-q15fx7rEeX'
    const decryptedData = CryptoJS.AES.decrypt(encryptedData, key)
    return decryptedData.toString(CryptoJS.enc.Utf8)
  }
}

module.exports = TokenGenerator
